<template>
  <div style="height: 100%; overflow: auto">
    <div class="info">
      <div class="info-title">基本信息</div>
      <el-descriptions
        class="margin-top"
        :column="columnNumber"
        labelClassName="policy-label-style"
        contentClassName="policy-label-style"
      >
        <el-descriptions-item v-for="item in basicInformation" :key="item.key">
          <template slot="label">
            {{ item.label }}
          </template>
          <span v-if="item.type == 'boolean'">
            {{ informationData[item.key] ? item.inactive : item.active }}
          </span>
          <span v-else-if="item.type == 'select'">
            {{ item.selectList[informationData[item.key]] || "" }}
          </span>
          <span v-else>{{ informationData[item.key] || "--" }}</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="info">
      <div class="info-title">终端信息</div>
      <div>
        <div class="terminal-type">
          产品版本：{{ informationData?.vehicleVersion?.versionName }}
        </div>
        <div class="terminal-info">
          <div
            class="info-item"
            v-for="(item, index) in informationData.terminalV2List"
            :key="index"
          >
            <div
              class="title"
              style="display: flex; justify-content: space-between"
            >
              <div style="display: flex; flex: 1">
                <div>
                  <span>{{ terminalName[item.deviceType] }}</span>
                </div>
                <span class="host2" v-if="item.mainFlag">主终端</span>
              </div>
              <div
                v-if="item.deviceType === 1"
                style="display: flex; align-items: center; cursor: pointer"
                @click="isPlayVideo = true"
              >
                <img
                  src="../../../assets/images/realTimePositioning/details-video.png"
                  style="width: 20px; height: 20px; margin-right: 4px"
                  alt=""
                  srcset=""
                />
                查看摄像头
              </div>
            </div>
            <div class="form">
              <div class="item">
                <div class="label required">终端编号：</div>
                <div class="info">
                  {{ item.terminalNo }}
                </div>
              </div>
              <div class="item">
                <div class="label required">SIM卡号：</div>
                <div class="info">
                  {{ item.sim }}
                </div>
              </div>
              <div class="item">
                <div class="label required">SIM卡供应商：</div>
                <div class="info">
                  {{ item.simType }}
                </div>
              </div>
              <div class="item">
                <div class="label required">激活日期：</div>
                <div class="info">
                  {{ item.activationTime }}
                </div>
              </div>
              <div class="item">
                <div class="label required">服务到期：</div>
                <div class="info">
                  {{ item.expireTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="info-title">安装信息</div>
      <el-descriptions
        class="margin-top"
        :column="4"
        labelClassName="policy-label-style"
        contentClassName="policy-label-style"
      >
        <el-descriptions-item v-for="item in installationInfo" :key="item.key">
          <template slot="label">
            {{ item.label }}
          </template>
          {{ informationData[item.key] || "--" }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="info">
      <div class="info-title">相关附件</div>
      <el-descriptions
        class="margin-top"
        :column="columnNumber"
        labelClassName="policy-label-style"
        contentClassName="policy-label-style"
      >
        <el-descriptions-item v-for="item,index in informationData.attachmentList || []" :key="index">
          <template slot="label">
            {{ attachmentNameList[item.attachmentType] || "--" }}
          </template>
          <div class="img-box" style="width: 88px; height: 88px;margin-right:8px;">
            <img
              style="width: 88px; height: 88px;border-radius: 4px;"
              :src="item.attachmentUrl"
              alt=""
            />
            <div class="img-mask">
              <i
                @click="handlePictureCardPreview(item.attachmentUrl)"
                class="el-icon-zoom-in"
              ></i>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <!-- <div class="annex">
        <div
          class="annex-item"
          v-for="(item, index) in informationData.attachmentList || []"
          :key="index"
        >
          <div class="img-box" style="width: 80px; height: 80px">
            <img
              style="width: 80px; height: 80px"
              :src="item.attachmentUrl"
              alt=""
            />
            <div class="img-mask">
              <i
                @click="handlePictureCardPreview(item.attachmentUrl)"
                class="el-icon-zoom-in"
              ></i>
            </div>
          </div>

          <div class="text-ellipsis">{{ item.attachmentName || "--" }}</div>
        </div>
      </div> -->
    </div>
    <div class="info">
      <div class="info-title">车主信息</div>
      <el-descriptions
        class="margin-top"
        :column="4"
        labelClassName="policy-label-style"
        contentClassName="policy-label-style"
      >
        <el-descriptions-item v-for="item in carInfo" :key="item.key">
          <template slot="label">
            {{ item.label }}
          </template>
          {{ informationData[item.key] || "--" }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-dialog
      title="查看摄像头"
      :visible.sync="isPlayVideo"
      width="368px"
      :before-close="() => (isPlayVideo = false)"
    >
      <div>
        <div
          v-for="(item, index) in cameraLineList"
          :key="index"
          style="
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            margin-bottom: 36px;
          "
        >
          <div>摄像头{{ item.camera }}</div>
          <div style="margin: 0 16px">-</div>
          <div>{{ item.name }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isPlayVideo = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" width="40%">
      <img style="width: 100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getVehicleDetails } from "@/api/vehicleApi";
import { getTreeNodeId } from "@/utils/treeHelper";
import { mapState } from "vuex";
import RtpPlayer from "@/views/RealTimePositioning/RtpPlayer.vue";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  components: { RtpPlayer },
  watch: {
    currentVehicle: {
      handler(val) {},
      deep: true,
    },
    isPlayVideo: {
      handler(val) {
        if (val) {
          this.init(this.informationData);
        } else {
          this.videoList = [];
        }
      },
    },
    screenWidth: {
      handler(val) {
        if (val > 1680) {
          this.columnNumber = 4;
        } else if (val > 1280) {
          this.columnNumber = 3;
        } else if (val > 880) {
          this.columnNumber = 2;
        } else {
          this.columnNumber = 1;
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState(["userConfig"]),
    realTimeConfig() {
      return this.userConfig.realTimeConfig;
    },
    speakVehicle() {
      const { currentVehicle } = this;
      if (!currentVehicle) return null;

      // 终端号
      const device = currentVehicle.N;
      // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      // const protocolType = ["GF-1078", "GB-1078"].includes(currentVehicle.T)
      //   ? 1
      //   : 2;
      const protocolType = currentVehicle.T.indexOf("GA-") < 0
        ? 1
        : 2;
      // 特殊协议 0: 不处理; 1: 粤标
      const SpecialSign = currentVehicle.percentageOfFlow === 6 ? 1 : 0;
      // 设备名称
      const plate = currentVehicle.P;
      // 车辆Id
      const vehicleId = currentVehicle.V;
      // 车组Id
      const groupId = currentVehicle.M;

      return {
        device,
        protocolType,
        SpecialSign,
        plate,
        vehicleId,
        groupId,
      };
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      informationData: {},
      basicInformation: [
        {
          label: "设备性质",
          key: "isCommercialVehicles",
          type: "boolean",
          active: "工机",
          inactive: "商车",
        },
        {
          label: "设备名称",
          key: "plate",
        },
        {
          label: "所属车组",
          key: "groupName",
        },
        {
          label: "设备类型",
          key: "machineryProduct",
        },
        // {
        //   label: "设备种类",
        //   key: "machineryEquipmentType",
        // },
        {
          label: "型号",
          key: "machineryProductNum",
        },
        // {
        //   label: "品牌",
        //   key: "vehicleBrand",
        // },
        {
          label: "机身编号（车架号）",
          key: "frameNo",
        },
        {
          label: "车牌号",
          key: "sbPlate",
        },
        // {
        //   label: "已工作时长",
        //   key: "workedTime",
        // },
        {
          label: "所属客户",
          key: "customerName",
        },
        {
          label: "作业工况",
          key: "workingConditionValue",
        },
        {
          label: "使用状态",
          key: "onState",
          type: "select",
          selectList: {
            1: "正常",
            2: "维修",
            3: "停用",
          },
        },
      ],
      installationInfo: [
        {
          label: "安装工",
          key: "installPerson",
        },
        {
          label: "安装时间",
          key: "installDate",
        },
      ],
      carInfo: [
        {
          label: "车主姓名",
          key: "owner",
        },
        {
          label: "联系电话",
          key: "phone",
        },
        {
          label: "紧急联系电话",
          key: "license",
        },
      ],
      carSource: [
        {
          label: "所属车组",
          key: "groupName",
        },
        {
          label: "车组信息",
          key: "owner",
        },
        {
          label: "车组电话",
          key: "phone",
        },
        {
          label: "机手信息",
          key: "driver",
        },
        {
          label: "机手电话",
          key: "driverPhone",
        },
      ],
      terminalInfo: [
        {
          label: "销售经理",
          key: "salesmanName",
        },
        {
          label: "安装人员",
          key: "installPerson",
        },
        {
          label: "安装时间",
          key: "installDate",
        },
        {
          label: "工单编号",
          key: "orderNumber",
        },
        {
          label: "产品版本",
          key: "equipmentType",
        },
        {
          label: "终端编号",
          key: "terminalNo",
        },
        {
          label: "SIM卡号",
          key: "sim",
        },
        {
          label: "流量(GB)",
          key: "datausage",
        },
        {
          label: "厂商型号",
          key: "productType",
        },
      ],
      videoList: [],
      isPlayVideo: false,
      cameraLineList: [],
      terminalName: [
        '',
        'OVP',
        'OBD',
        '有线GPS',
        '无线GPS',
        'PFT',
        'BSD',
        'AEB'
      ],
      attachmentNameList: {
        azqzp: '安装前照片',
        azhzp: '安装后照片',
        mpzp: '铭牌照片',
        zjwzzp: '主机位置照片',
        xszzp: '行驶证照片',
        jxyqc45dzp: '整车照片',
        ysd: '验收单',
        qtzp: '其它照片',
        // mpzp: '',
        // mpzp: '',
      },
      columnNumber: 4,
      screenWidth: window.innerWidth,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    async getList() {
      try {
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
        };
        let res = await getVehicleDetails(data);
        if (res.flag == 1) {
          res.obj.terminalNo =
            res.obj.vehicleTerminals && res.obj.vehicleTerminals.length
              ? res.obj.vehicleTerminals.map((val) => val.terminalNo).toString()
              : "";
          this.informationData = res.obj;
        }
      } catch (error) {}
    },
    init(data) {
      let cameraLineList = [];
      let cameraName = ["", "右摄像头", "后摄像头", "左摄像头", "前摄像头"];
      JSON.parse(data.cameraLine).map((val, ind) => {
        // cameraLine.push(val);
        // cameraCH.push(JSON.parse(data.cameraCH)[ind]);
        cameraLineList.push({
          name: JSON.parse(data.cameraCH)[ind] || cameraName[val],
          camera: val,
        });
      });
      this.cameraLineList = cameraLineList;
      // this.videoPlate = data.P;
      // const videoList = [];
      // const channels = this.parseChannels(data.camreaLine);
      // const cameraCH = this.parseChannels(data.cameraCH);

      // // 终端号
      // const device = data.N;
      // // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
      // const protocolType = ["GF-1078", "GB-1078"].includes(data.T) ? 1 : 2;
      // // 特殊协议 0: 不处理; 1: 粤标
      // const SpecialSign = data.percentageOfFlow === 6 ? 1 : 0;
      // // 设备名称
      // const plate = data.P;
      // // 车辆Id
      // const vehicleId = data.V;
      // // 车组Id
      // const groupId = data.M;

      // channels.forEach((channel,ind) => {
      //   const key = getTreeNodeId(data.M, data.V, channel);
      //   if (videoList.some((obj) => obj.key === key)) return;
      //   videoList.push({
      //     key,
      //     channel,
      //     channelsName: cameraCH ? cameraCH[ind] : '',
      //     device,
      //     protocolType,
      //     SpecialSign,
      //     plate,
      //     vehicleId,
      //     groupId
      //   });
      // });
      // this.videoList = videoList;
    },
    parseChannels(channelStr) {
      try {
        return JSON.parse(channelStr);
      } catch (err) {
        //
      }
      return [];
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    }
  },
};
</script>

<style lang="scss" scoped>
.info {
  // height: 100%;
  margin-bottom: 10px;
  padding: 14px 14px 0;
  background-color: #fff;
  overflow: hidden;
  border-radius: 0px 10px 10px 10px;
  &-title {
    position: relative;
    line-height: 24px;
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    padding-bottom: 12px;
    padding-left: 15px;
    border-bottom: 1px dashed #dedede;
  }
  &-title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 24px;
    left: 0;
    top: 0;
    bottom: 12px;
    border-radius: 3px 0 0 3px;
    background-color: rgba(66, 120, 201, 1);
  }
  ::v-deep .el-descriptions {
    padding-top: 24px;
    .el-descriptions-item {
      padding-bottom: 24px;
      font-size: 14px;
      color: #333333;
    }
  }
  .terminal-info {
    display: flex;
    flex-wrap: wrap;
    .info-item {
      width: 324px;
      padding: 10px;
      background-color: #e2f1ff;
      border: 1px solid #d7e2f3;
      margin-right: 30px;
      box-shadow: 0px 2px 6px 1px rgba(66, 120, 201, 0.18);
      border-radius: 10px 10px 10px 10px;
      margin-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-right: 0;
      }
      .title {
        //padding: 0 0 5px 0;
        // border-bottom: 1px solid #e7e7e7;
        margin-bottom: 10px;
        position: relative;
        font-weight: bold;
        font-size: 14px;
        color: #4278c9;
        .host {
          position: absolute;
          right: 0;
          font-size: 12px;
          background-image: linear-gradient(#a5caff, #6ba8ff);
          padding: 2px 6px;
          border-radius: 0 5px 0 5px;
          color: #fff;
        }
        .host2 {
          font-weight: 400;
          font-size: 12px;
          background-color: #4278c9;
          padding: 2px 6px;
          border-radius: 0 10px 0 10px;
          color: #fff;
          margin-left: 8px;
        }
      }
      .form {
        background-color: #ffffff;
        padding: 16px 8px;
        border-radius: 4px 4px 4px 4px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          padding-left: 8px;

          font-size: 12px;
          position: relative;
          .label {
            text-align: right;
            position: relative;
          }
          // .label::before {
          //   position: absolute;
          //   left: -8px;
          //   top: 0;
          //   content: "*";
          //   color: red;
          //   font-weight: bold;
          //   font-size: 20px;
          // }
        }
        .info {
          padding: 0;
          margin: 0;
          border-radius: 0;
        }
      }
    }
  }
  .terminal-type {
    font-size: 14px;
    color: #333333;
    margin: 24px 0;
  }
  .annex {
    display: flex;
    gap: 80px;
    max-width: 100%;
    margin-top: 24px;
    overflow: auto;
    &-item {
      width: 88px;
      // img {
      //   width: 88px;
      //   height: 88px;
      //   border-radius: 4px;
      // }
      // div {
      //   width: 88px;
      //   text-align: center;
      //   margin-top: 16px;
      // }
    }
  }
}
.img-box {
  position: relative;
  overflow: hidden;
  &:hover {
    .img-mask {
      display: flex;
    }
  }
}
.img-mask {
  display: none;
  position: absolute;
  width: 88px;
  height: 88px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  color: #ffffff;
  align-items: center;
  justify-content: space-around;
}
</style>
