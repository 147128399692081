<template>
  <div class="RtpMap">
    <BSJMap ref="mapRef" :center="centerLng" @loaded="onMapLoad">
      <!-- 聚合点 -->
      <MapClusterer
        ref="clusterObj"
        :points="clusterPoints"
        :minClusterSize="minClusterSize"
        pointKey="key"
        :markerOffset="markerOffset"
        @markerClick="onMarkerClick"
      >
        <template #marker="{ point }">
          <!-- <Iconfont
            :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
            :size="32"
            :rotate="point.data.F || 0"
          ></Iconfont>-->
          <!-- <Iconfont name="icon-a-zu517" :size="32" :rotate="point.data.F || 0"></Iconfont> -->
          <Iconfont
            :size="48" :style="{filter: `grayscale(${point.data.Z != 5 ? '0%' : '100%'})`}"
            :height="52"
            :name="point.data.iconUrl"
          ></Iconfont>
        </template>
      </MapClusterer>

      <!-- 展示地图信息窗体 -->
      <InfoWindow
        ref="windowObj"
        v-model="infoVisible"
        :lngLat="windowPoint"
        :offset="[-4, -15]"
        :width="236"
        :height="0"
        autoMove
      >
        <div style="width: 236px;background: #FFFFFF;box-shadow: 0px 0px 20px 1px rgba(51,51,51,0.12);border-radius: 10px;padding: 12px 10px;position: relative;">
          <div style="font-size: 14px;color: #999999;margin-bottom: 8px">当前位置</div>
          <div class="font-size: 14px;color: #333333;">
          <AddressText
            :autoScroll="false"
            :lng="currentVehicle.X"
            :lat="currentVehicle.Y"
          ></AddressText>
          </div>
          <i class="el-icon-close" style="position: absolute;top:8px;right:8px;color:#333333;font-size:24px;cursor: pointer;" @click="infoVisible = false"></i>
        </div>
        <!-- <RtpWindow
          :currentVehicle="currentVehicle"
          :speakVehicle="speakVehicle"
          @close="infoVisible=false"
          @playClick="$emit('playClick', $event)"
          @speakClick="$emit('speakClick',$event)"
        ></RtpWindow> -->
      </InfoWindow>
    </BSJMap>
    <div class="left-adsorption">
      <div class="ranging">
        <div class="ranging-state">
          <div class="ranging-state-left">
            实时状态<span style="font-size: 12px; color: #999999"
              >（更新于{{
                currentVehicle.PT
                  ? dayjs(currentVehicle.PT).format("YYYY-MM-DD HH:mm:ss")
                  : dayjs(currentVehicle.R).format("YYYY-MM-DD HH:mm:ss")
              }}）</span
            >
          </div>
          <!-- <div class="ranging-state-alarm">
          <img src="../../../assets/images/realTimePositioning/ss-alarm.png" alt="" srcset="">
          <marquee style="font-size: 12px;color: #FF5B40;">{{ currentVehicle.B || currentVehicle.BD }}</marquee>
        </div> -->
          <div class="ranging-state-right" @click="handleChange">
            <div :class="['Header-state', 'Header-state' + currentVehicle.Z]">
              {{ getStateLabel }}
            </div>
            <div class="ranging-state-right-r">
              <img
                src="../../../assets/images/realTimePositioning/map-sx.png"
                alt=""
                srcset=""
              />
            </div>
            <!-- <span>刷新</span>   -->
          </div>
        </div>
        <div class="ranging-cell">
          <div
            class="ranging-cell-item"
            v-for="(item, index) in stateList[currentVehicle.manufactorType] ||
            stateList['MR']"
            :key="index"
          >
            <img :src="item.icon" alt="" srcset="" />
            <div class="ranging-cell-item-label">{{ item.label }}：</div>
            <div class="ranging-cell-item-value" v-if="item.type == 'switch'">
              {{
                currentVehicle[item.key] == item.inactiveValue
                  ? item.inactiveText
                  : item.activeText
              }}
            </div>
            <div v-else class="ranging-cell-item-value">
              {{ item.maxNum && (currentVehicle[item.key] || 0) > item.maxNum ?  '--' : currentVehicle[item.key] }}{{ item.units }}
            </div>
          </div>
        </div>
        <!-- <div class="ranging-statistics" v-if="currentVehicle.isCommercialVehicles">
        <div>
          <div class="label">今日工时</div>
          <div class="v">
            <span>{{
              currentVehicle.inWorkingTime
                ? getDwellTime(currentVehicle.inWorkingTime, 0)
                : "--"
            }}</span
            >小时<span>{{
              currentVehicle.inWorkingTime
                ? getDwellTime(currentVehicle.inWorkingTime, 1)
                : "--"
            }}</span
            >分
          </div>
        </div>
        <div class="bebebe">
          <div class="label">持续{{ getStateLabel }}</div>
          <div class="v">
            <div v-if="currentVehicle.Dm && getDwellTime6(currentVehicle.Dm * 1000, 0)"><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 0) : '--' }}</span>天</div>
            <div><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 1) : '--' }}</span>时</div>
            <div><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 2) : '--' }}</span>分</div>
            <div v-if="currentVehicle.Dm && !getDwellTime6(currentVehicle.Dm * 1000, 0)"><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 3) : '--' }}</span>秒</div>
          </div>
        </div>
        <div class="bebebe">
          <div class="label">今日刹车</div>
          <div class="v">
            <span>{{ currentVehicle.shaCheAlarmCount || '--' }}</span
            >次
          </div>
        </div>
        <div class="bebebe">
          <div class="label">其他告警</div>
          <div class="v">
            <span>{{ currentVehicle.alarmCommercialVehiclesCount || '--' }}</span
            >次
          </div>
        </div>
      </div> -->
        <!-- <div class="ranging-statistics" v-else>
        <div>
          <div class="label">今日工时</div>
          <div class="v">
            <span>{{
              currentVehicle.inWorkingTime
                ? getDwellTime(currentVehicle.inWorkingTime, 0)
                : "--"
            }}</span
            >小时<span>{{
              currentVehicle.inWorkingTime
                ? getDwellTime(currentVehicle.inWorkingTime, 1)
                : "--"
            }}</span
            >分
          </div>
        </div>
        <div class="bebebe">
          <div class="label">持续{{ getStateLabel }}</div>
          <div class="v">
            <div v-if="currentVehicle.Dm && getDwellTime6(currentVehicle.Dm * 1000, 0)"><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 0) : '--' }}</span>天</div>
            <div><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 1) : '--' }}</span>时</div>
            <div><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 2) : '--' }}</span>分</div>
            <div v-if="currentVehicle.Dm && !getDwellTime6(currentVehicle.Dm * 1000, 0)"><span >{{ currentVehicle.Dm ? getDwellTime6(currentVehicle.Dm * 1000, 3) : '--' }}</span>秒</div>
          </div>
        </div>
        <div class="bebebe">
          <div class="label">今日告警</div>
          <div class="v">
            <span>{{ currentVehicle.alarmCommercialVehiclesCount || '--' }}</span
            >次
          </div>
        </div>
      </div> -->
        <!-- <div class="ranging-item">
        <span>车组：</span>
        <div>{{ currentVehicle.groupName || '--' }}</div>
      </div>
      <div class="ranging-item">
        <span>位置：</span>
        <div style="display:flex;flex-wrap:wrap;">
          <AddressText
            :autoScroll="false"
            :lng="currentVehicle.X"
            :lat="currentVehicle.Y"
          ></AddressText>
          <span style="font-size: 12px;color: #999999;">（更新于{{ currentVehicle.PT
                ? dayjs(currentVehicle.PT).format("YYYY-MM-DD HH:mm:ss")
                : dayjs(currentVehicle.R).format("YYYY-MM-DD HH:mm:ss") }}）</span>
        </div>
      </div> -->
      </div>
      <div class="js-info">
        <div class="js-info-item">
          <div class="js-info-item-r">
            <img :src="appConfig.base2GUrl + this.currentVehicle.vehicleQRUrl" alt="" srcset="" />
            <span class="el-upload-list__item-actions">
              <span
                @click="handlePictureCardPreview"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                @click="handleDownload"
              >
                <i class="el-icon-download"></i>
              </span>
            </span>
          </div>
          <div>机械二维码</div>
        </div>
        <div class="js-info-item">
          <img
            :src="currentVehicle.JiShouTXUrl && currentVehicle.JiShouTXUrl != '--' ? currentVehicle.JiShouTXUrl : require('../../../assets/images/realTimePositioning/智障玩意.png')"
            style="border-radius: 50%"
            alt=""
            srcset=""
          />
          <div>{{ currentVehicle.JiShouName }}<span>(机手)</span></div>
        </div>
      </div>
      <div class="ranging">
        <div class="ranging-state">
          <div class="ranging-state-left">
            安全评分<span style="font-size: 12px; color: #999999"
              >（更新于{{
                currentVehicle.safetyRatingDate
                  ? dayjs(currentVehicle.safetyRatingDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : "----"
              }}）</span
            >
          </div>
        </div>
        <div
          style="
            font-weight: bold;
            font-size: 32px;
            color: #00bc0d;
            text-align: center;
            padding-top: 32px;
            padding-bottom: 32px;
          "
        >
          {{ currentVehicle.safetyRating }}
        </div>
      </div>
    </div>
    <div class="right-adsorption">
      <div class="ranging">
        <div class="ranging-state">
          <div class="ranging-state-left">今日工况</div>
        </div>
        <div class="statistics">
          <div class="statistics-item statistics-down">
            <img
              src="../../../assets/images/realTimePositioning/statistics-kg.png"
              class="statistics-item-icon"
              alt=""
              srcset=""
            />
            <div class="statistics-item-content">
              <div class="statistics-item-content-top">开工时间</div>
              <div class="statistics-item-content-down">
                {{ currentVehicle.firstOnlineTime || "--" }}
              </div>
            </div>
          </div>
          <div class="statistics-item statistics-left statistics-down">
            <img
              src="../../../assets/images/realTimePositioning/statistics-tg.png"
              class="statistics-item-icon"
              alt=""
              srcset=""
            />
            <div class="statistics-item-content">
              <div class="statistics-item-content-top">停工时间</div>
              <div class="statistics-item-content-down">
                {{ currentVehicle.lastOnlineTime || "--" }}
              </div>
            </div>
          </div>
          <div class="statistics-item">
            <img
              src="../../../assets/images/realTimePositioning/statistics-gs.png"
              class="statistics-item-icon"
              alt=""
              srcset=""
            />
            <div class="statistics-item-content">
              <div class="statistics-item-content-top">今日工时</div>
              <div class="statistics-item-content-down" style="display: flex">
                <div
                  v-if="
                    currentVehicle.sumWork &&
                    getDwellTime6(currentVehicle.sumWork * 1000, 0)
                  "
                >
                  <span>{{
                    currentVehicle.sumWork
                      ? getDwellTime6(currentVehicle.sumWork * 1000, 0)
                      : "--"
                  }}</span
                  >天
                </div>
                <div>
                  <span>{{
                    currentVehicle.sumWork
                      ? getDwellTime6(currentVehicle.sumWork * 1000, 1)
                      : "--"
                  }}</span
                  >时
                </div>
                <div>
                  <span>{{
                    currentVehicle.sumWork
                      ? getDwellTime6(currentVehicle.sumWork * 1000, 2)
                      : "--"
                  }}</span
                  >分
                </div>
                <div
                  v-if="
                    currentVehicle.sumWork &&
                    !getDwellTime6(currentVehicle.sumWork * 1000, 0)
                  "
                >
                  <span>{{
                    currentVehicle.sumWork
                      ? getDwellTime6(currentVehicle.sumWork * 1000, 3)
                      : "--"
                  }}</span
                  >秒
                </div>
              </div>
            </div>
          </div>
          <div class="statistics-item statistics-left">
            <img
              src="../../../assets/images/realTimePositioning/statistics-sc.png"
              class="statistics-item-icon"
              alt=""
              srcset=""
            />
            <div class="statistics-item-content">
              <div class="statistics-item-content-top">开工时长</div>
              <div class="statistics-item-content-down" style="display: flex">
                <div
                  v-if="
                    currentVehicle.inWorkingTime &&
                    getDwellTime6(currentVehicle.inWorkingTime * 1000, 0)
                  "
                >
                  <span>{{
                    currentVehicle.inWorkingTime
                      ? getDwellTime6(currentVehicle.inWorkingTime * 1000, 0)
                      : "--"
                  }}</span
                  >天
                </div>
                <div>
                  <span>{{
                    currentVehicle.inWorkingTime
                      ? getDwellTime6(currentVehicle.inWorkingTime * 1000, 1)
                      : "--"
                  }}</span
                  >时
                </div>
                <div>
                  <span>{{
                    currentVehicle.inWorkingTime
                      ? getDwellTime6(currentVehicle.inWorkingTime * 1000, 2)
                      : "--"
                  }}</span
                  >分
                </div>
                <div
                  v-if="
                    currentVehicle.inWorkingTime &&
                    !getDwellTime6(currentVehicle.inWorkingTime * 1000, 0)
                  "
                >
                  <span>{{
                    currentVehicle.inWorkingTime
                      ? getDwellTime6(currentVehicle.inWorkingTime * 1000, 3)
                      : "--"
                  }}</span
                  >秒
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ranging" style="margin-top: 16px">
        <div class="ranging-state">
          <div class="ranging-state-left">今日告警</div>
        </div>
        <div class="chart">
          <div style="height: 270px; width: 200px">
            <Chart
              style="height: 100%"
              ref="chartBox"
              :option="PieOptions"
            ></Chart>
          </div>
          <div class="chart-legend">
            <div
              class="chart-legend-item"
              v-for="(item, index) in currentVehicle.retVehicleAlarmCount || {}"
              :key="index"
            >
              <div
                class="chart-legend-item-round"
                :style="{ backgroundColor: pieColor[index] }"
              ></div>
              <div class="chart-legend-item-n text-ellipsis">{{ index }}</div>
              <div class="chart-legend-item-bar"></div>
              <div class="chart-legend-item-v">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img style="height: 66vh;" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import BSJMap, { InfoWindow, MapClusterer } from "@/components/AMap";
import AddressText from "@/components/AddressText";
import { getDwellTime3, getDwellTime6 } from "@/utils/formatDate";

import GPS from "@/utils/address";
import { mapState } from "vuex";
import Chart from "@/components/Chart/Charts";

// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';

import { amapGetIp } from "@/api/weather";
import vehiclePicture from "@/utils/vehiclePicture";
import dayjs from "dayjs";
import { appConfig } from '@/config/appConfig';

export default {
  name: "RtpMap",
  components: {
    BSJMap,
    MapClusterer,
    InfoWindow,
    AddressText,
    Chart,
  },
  model: {
    prop: "currentVehicle",
  },
  props: {
    /**
     * 聚合的最小数量。默认值为2，即小于2个点则不能成为一个聚合
     */
    minClusterSize: {
      type: Number,
      default: 10,
    },
    currentVehicle: {
      type: Object,
      default: null,
    },
    vehicleList: {
      type: Array,
      default() {
        return [];
      },
    },
    speakVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    // 地图实例
    this.mapObj = null;
    return {
      markerOffset: [0, 0],
      infoVisible: false,
      centerLng: null,
      ruleIsOn: false,
      getDwellTime6,
      stateList: {
        ZK: [
          {
            label: "当前速度",
            icon: require("../../../assets/images/realTimePositioning/map-sd.png"),
            key: "S",
            units: "km/h",
          },
          {
            label: "今日里程",
            icon: require("../../../assets/images/realTimePositioning/map-lc.png"),
            key: "L",
            units: "km",
            maxNum: 500
          },
          {
            label: "ACC状态",
            icon: require("../../../assets/images/realTimePositioning/map-acczt.png"),
            key: "ACC",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 0,
          },
          {
            label: "AEB状态",
            icon: require("../../../assets/images/realTimePositioning/map-aebzt.png"),
            key: "online",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: true,
          },
          {
            label: "左转向灯",
            icon: require("../../../assets/images/realTimePositioning/map-zzxd.png"),
            key: "signalLeftOn",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 1,
          },
          {
            label: "右转向灯",
            icon: require("../../../assets/images/realTimePositioning/map-yzxd.png"),
            key: "signalRightOn",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 1,
          },
          {
            label: "刹车灯",
            icon: require("../../../assets/images/realTimePositioning/map-scd.png"),
            key: "signalBreakOn",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 1,
          },
        ],
        HK: [
          {
            label: "当前速度",
            icon: require("../../../assets/images/realTimePositioning/map-sd.png"),
            key: "S",
            units: "km/h",
          },
          {
            label: "今日里程",
            icon: require("../../../assets/images/realTimePositioning/map-lc.png"),
            key: "L",
            units: "km",
            // maxNum: 500
          },
          {
            label: "ACC状态",
            icon: require("../../../assets/images/realTimePositioning/map-acczt.png"),
            key: "ACC",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 0,
          },
          {
            label: "AEB状态",
            icon: require("../../../assets/images/realTimePositioning/map-aebzt.png"),
            key: "online",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: true,
          },
          {
            label: "左转向灯",
            icon: require("../../../assets/images/realTimePositioning/map-zzxd.png"),
            key: "signalLeftOn",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 1,
          },
          {
            label: "右转向灯",
            icon: require("../../../assets/images/realTimePositioning/map-yzxd.png"),
            key: "signalRightOn",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 1,
          },
          {
            label: "双闪灯",
            icon: require("../../../assets/images/realTimePositioning/map-ssd.png"),
            key: "hazLight",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 1,
          },
          {
            label: "自检结果",
            icon: require("../../../assets/images/realTimePositioning/map-zjjg.png"),
            key: "selfCheck",
            type: "switch",
            activeText: "未通过",
            inactiveText: "通过",
            inactiveValue: 1,
          },
          {
            label: "系统开关",
            icon: require("../../../assets/images/realTimePositioning/map-xtkg.png"),
            key: "sysSW",
            type: "switch",
            activeText: "未闭合",
            inactiveText: "闭合",
            inactiveValue: 1,
          },
          {
            label: "总线状态",
            icon: require("../../../assets/images/realTimePositioning/map-zxzt.png"),
            key: "busSta",
            type: "switch",
            activeText: "正常",
            inactiveText: "异常",
            inactiveValue: 1,
          },
        ],
        MR: [
          {
            label: "当前速度",
            icon: require("../../../assets/images/realTimePositioning/map-sd.png"),
            key: "S",
            units: "km/h",
          },
          {
            label: "今日里程",
            icon: require("../../../assets/images/realTimePositioning/map-lc.png"),
            key: "L",
            units: "km",
          },
          {
            label: "电压",
            icon: require("../../../assets/images/realTimePositioning/map-dy.png"),
            key: "voltage",
            units: "V",
          },
          {
            label: "转速",
            icon: require("../../../assets/images/realTimePositioning/map-lc.png"),
            key: "engineRoundsObjNumber",
            units: "r/min",
          },
          {
            label: "ACC状态",
            icon: require("../../../assets/images/realTimePositioning/map-acczt.png"),
            key: "ACC",
            type: "switch",
            activeText: "关",
            inactiveText: "开",
            inactiveValue: 0,
          },
        ],
      },
      PieOptions: {},
      pieColor: {},
      dialogImageUrl: '',
      dialogVisible: false,
      appConfig,
    };
  },
  computed: {
    // 聚合点
    clusterPoints() {
      const { vehicleList } = this;
      return vehicleList.map((obj, indd) => {
        const point =
          obj.Y && obj.X
            ? GPS.delta(obj.Y, obj.X)
            : GPS.delta(116.40121, 39.907795);
        return {
          key: obj.key || indd,
          lng: point.lon,
          lat: point.lat,

          // lng: 116.40121,
          // lat: 39.907795,

          data: obj,
        };
      });
    },
    getStateLabel() {
      const { currentVehicle } = this;
      if (currentVehicle) {
        // if (currentVehicle.Z === 2 && currentVehicle.isCommercialVehicles) return "开工";
        // if (currentVehicle.Z === 4 && currentVehicle.isCommercialVehicles) return "停工";
        // if (currentVehicle.Z === 0) return "怠速";
        // if (currentVehicle.Z === 1) return "行驶";
        // if (currentVehicle.Z === 2) return "作业";
        // if (currentVehicle.Z === 3) return "通电";
        // if (currentVehicle.Z === 4) return "熄火";
        // if (currentVehicle.Z === 5) return "离线";

        if (currentVehicle.Z === 0) return "停工";
        if (currentVehicle.Z === 1) return "开工";
        if (currentVehicle.Z === 5) return "离线";
        return "从未上线";
      }
      return "从未上线";
    },
    windowPoint() {
      // return [116.40121, 39.907795];
      const { currentVehicle } = this;
      if (!currentVehicle || !currentVehicle.Y || !currentVehicle.X) {
        this.infoVisible = false;
        return [];
      }
      this.infoVisible = true;
      const point = GPS.delta(currentVehicle.Y, currentVehicle.X);
      this.centerLng = [point.lon, point.lat];
      return [point.lon, point.lat];
    },
    ...mapState(["realIP"]),
  },
  watch: {
    clusterPoints(val) {
      if (val.length <= 0) {
        // this.infoVisible = false;
      }
    },
    currentVehicle: {
      handler(val) {
        const { clusterPoints } = this;
        this.$nextTick(()=> {
          this.initPie();
        })
        // if (!val || clusterPoints.length <= 0) {
        //   this.infoVisible = false;
        //   return;
        // }
        // this.infoVisible = true;
      },
      deep: true,
    },
    windowPoint: {
      handler() {
        this.$nextTick(() => {
          this.panToCenter();
        });
      },
      deep: true,
    },
  },
  beforeMount() {
        this.$nextTick(()=> {
          this.initPie();
        })
  },
  created() {
    // amapGetIp({
    //   key: "60f85fa7d1c353b0a2ffb92083e365f0",
    //   ip: this.realIP,
    // }).then((res) => {
    //   // amapGetIp({ key: '60f85fa7d1c353b0a2ffb92083e365f0',ip: '113.71.214.232' }).then(res=>{
    //   if (res.rectangle && res.rectangle.length > 0) {
    //     let rectangle = res.rectangle.split(";");
    //     let rectangle1 = rectangle[0].split(",");
    //     let rectangle2 = rectangle[1].split(",");
    //     let Lng =
    //       parseFloat(rectangle1[0]) +
    //       (parseFloat(rectangle1[0]) - parseFloat(rectangle2[0]));
    //     let Lat =
    //       parseFloat(rectangle1[1]) +
    //       (parseFloat(rectangle1[1]) - parseFloat(rectangle2[1]));
    //     this.centerLng = [Lng, Lat];
    //   }
    // });
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    dayjs,
    // getProductTypeIconName,
    panToCenter() {
      const { windowPoint } = this;
      const { mapRef } = this.$refs;
      if (mapRef) {
        mapRef.panTo(windowPoint);
        this.$forceUpdate();
      }
    },
    async setFitView() {
      await this.$nextTick();

      const { clusterObj } = this.$refs;
      if (clusterObj) {
        clusterObj.setFitView();
      }
    },
    onMarkerClick(obj) {
      this.infoVisible = true;
      this.$emit("input", obj.data);
    },
    // 地图加载完成
    onMapLoad(map) {
      this.mapObj = map;
    },
    // 测距工具
    hadnleRuler() {
      if (this.ruleIsOn) return;

      let startMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31), //图标大小
          imageSize: new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/start.png",
        }),
      };
      let endMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31), //图标大小
          imageSize: new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/end.png",
        }),
        offset: new AMap.Pixel(-9, -31),
      };
      let midMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31), //图标大小
          imageSize: new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/mid.png",
        }),
        offset: new AMap.Pixel(-9, -31),
      };
      let lineOptions = {
        strokeStyle: "solid",
        strokeColor: "#FF33FF",
        strokeOpacity: 1,
        strokeWeight: 2,
      };
      var rulerOptions = {
        startMarkerOptions: startMarkerOptions,
        midMarkerOptions: midMarkerOptions,
        endMarkerOptions: endMarkerOptions,
        lineOptions: lineOptions,
      };
      const that = this;
      this.mapObj.plugin(["AMap.RangingTool"], function () {
        const ruler = new AMap.RangingTool(that.mapObj, rulerOptions);
        ruler.turnOn();

        that.ruleIsOn = true;

        AMap.event.addListener(ruler, "end", function (e) {
          ruler.turnOff();

          that.ruleIsOn = false;
        });
      });
    },
    vehicleIconName(val) {
      if (!val.machineryProductType) return '';
      return vehiclePicture(
        val.machineryProductType,
        val.machineryEquipmentType,
        val.Z,
        true
      );
    },
    handleChange() {
      this.$emit("change");
    },
    getDwellTime(e, ind) {
      return getDwellTime3(e * 1000)[ind];
    },
    initPie() {
      // 颜色
      const colorList = [
        "rgba(41, 91, 255, 1)",
        "rgba(97, 216, 235, 1)",
        "rgba(167, 123, 243, 1)",
        "rgba(251, 194, 38, 1)",
        "rgba(248, 248, 82, 1)",
        "rgba(84, 223, 139, 1)",
        "rgba(120, 174, 255, 1)",
        "rgba(152, 102, 222, 1)",
        "rgba(204, 121, 232, 1)",
        "rgba(255, 178, 89, 1)",
        "rgba(0, 186, 173, 1)",
        "rgba(172, 51, 193, 1)",
      ];
      let data = [];
      let i = 0;
      if (!this.currentVehicle.retVehicleAlarmCount) {
        return false;
      }
      for (const key in this.currentVehicle.retVehicleAlarmCount) {
        data.push({
          name: key,
          value: this.currentVehicle.retVehicleAlarmCount[key],
        });
        this.pieColor[key] = colorList[i];
        i++;
      }

      this.PieOptions = {
        title: {
          show: true,
          text: `{v|总次数}
{t|${this.currentVehicle.vehicleAlarmCount}}`,
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              v: {
                fontSize: 14,
                color: "#333",
              },
              t: {
                fontSize: 24,
                color: "#333",
                fontWeight: "bold",
              },
            },
          },
        },
        tooltip: {
          show: true,
          trigger: "item",
          // formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          data: data,
          show: false,
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: "#C6D1DB",
            fontSize: "12px",
          },
          tooltip: {
            show: true,
          },
          align: "left",
          verticalAlign: "bottom",
          bottom: "1%",
          // 		top: 'middle',
          orient: "horizontal",
        },
        series: [
          {
            type: "pie",
            radius: ["65%", "80%"],
            center: ["50%", "50%"],
            clockwise: true,
            avoidLabelOverlap: true,
            // hoverOffset: 30,
            label: {
              show: false,
              position: "left",
              // normal: {
              //   show: false,
              //   position: 'inner',
              //   // formatter: '{d}',
              //   fontSize: 7,
              // },
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            data: data,
          },
        ],
      };
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = appConfig.base2GUrl + this.currentVehicle.vehicleQRUrl;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      window.open(appConfig.base2GUrl + this.currentVehicle.vehicleQRUrl);
    }
  },
};
</script>

<style lang="scss" scoped>
.RtpMap {
  position: relative;
  width: 100%;
  height: 100%;
}
::v-deep .amap-toolbar {
  display: none !important;
}
::v-deep .amap-controls {
  display: none !important;
}
.left-adsorption {
  position: absolute;
  left: 16px;
  top: 16px;
  .js-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 402px;
    padding: 32px 12px;
    margin-top: 16px;
    margin-bottom: 16px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 1px rgba(120, 120, 120, 0.2);
    border-radius: 8px;
    &-item {
      img {
        width: 100px;
        height: 100px;
        display: block;
        margin: 0 auto 8px;
      }
      div {
        text-align: center;
        font-size: 14px;
        color: #333333;
      }
      span {
        font-size: 12px;
        color: #999999;
      }
      &-r {
        width: 100px;
        height: 100px;
        position: relative;
        > .el-upload-list__item-actions{
          display: flex;
          align-items: center;
          justify-content: space-around;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          cursor: default;
          opacity: 0;
          background-color: rgba(0, 0, 0, .5);
          transition: opacity .3s;
          span {
            color: #fff;
            font-size: 20px;
            cursor: pointer;
          }
        }
        .el-upload-list__item-actions:hover {
          opacity: 1;
        }
      }
    }
  }
}
.right-adsorption {
  position: absolute;
  right: 16px;
  top: 16px;
  .statistics {
    display: flex;
    flex-wrap: wrap;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 50%;
      padding: 16px;
      &-icon {
        width: 50px;
        height: 50px;
        margin-right: 12px;
      }
      &-content {
        height: calc(100% - 4px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-top {
          font-size: 14px;
          color: #999999;
        }
        &-down {
          font-size: 16px;
          color: #333333;
        }
      }
    }
    &-left::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 32px;
      width: 1px;
      background-color: #f5f5f5;
    }
    &-down {
      border-bottom: 1px solid #f5f5f5;
    }
  }
  .ranging {
    padding: 0;
    &-state {
      padding: 12px;
    }
  }
  .chart {
    display: flex;
    &-legend {
      flex: 1;
      height: 270px;
      padding-bottom: 16px;
      overflow: auto;
      &-item {
        display: flex;
        align-items: center;
        margin-top: 16px;
        &-round {
          width: 10px;
          height: 10px;
          margin-right: 8px;
          border-radius: 50%;
          background: #295bff;
        }
        &-n {
          flex: 1;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
        }
        &-bar {
          height: 14px;
          width: 2px;
          margin: 0 8px;
          border-radius: 1px;
          background-color: #999;
        }
        &-v {
          width: 70px;
          font-weight: bold;
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }
}
.ranging {
  width: 402px;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(120, 120, 120, 0.2);
  border-radius: 8px;
  &-state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #f5f5f5;
    &-right {
      display: flex;
      align-items: center;
      &-r {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // width: 54px;
        padding: 0 6px;
        height: 24px;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        margin-left: 10px;
        background: #f5f5f5;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .Header {
      &-state {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #999999;
        padding: 0 8px;
        background: #eeeeee;
        border-radius: 2px;
        border: 1px solid #e1e1e1;
      }
      &-state0 {
        color: #fca118;
        background: #fff5e7;
        border: 1px solid #ffe4bd;
      }
      &-state1 {
        color: #00bc0d;
        background: #e5f8e6;
        border: 1px solid #b7edba;
      }
      &-state2 {
        color: #fca118;
        background: #fff5e7;
        border: 1px solid #ffe4bd;
      }
      &-status3 {
        color: #3470ff;
        background: #eaf0ff;
        border: 1px solid #c5d6ff;
      }
    }
    &-left {
      position: relative;
      display: flex;
      align-items: center;
      // gap: 8px;
    }
    &-left::before {
      content: "";
      position: absolute;
      left: -12px;
      top: 0;
      bottom: 0;
      width: 4px;
      background: #4278c9;
    }
    &-alarm {
      flex: 1;
      display: flex;
      align-items: center;
      height: 24px;
      margin: 0 8px;
      background: rgba(255, 216, 216, 0.3);
      border-radius: 28px 28px 28px 28px;
      image {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }
  &-cell {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    &-item {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 16px;
      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
      &-label {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
      &-value {
        font-weight: 400;
        font-size: 14px;
        color: #0080ff;
      }
    }
  }
  &-item {
    display: flex;
    margin-top: 16px;
    font-size: 14px;
    color: #333333;
    span {
      white-space: nowrap;
      color: rgba(153, 153, 153, 1);
    }
  }
  &-statistics {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    background: #eff7ff;
    > div {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      .label {
        color: #999999;
        margin-bottom: 5px;
      }
      .v {
        display: flex;
        color: #333;
      }
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
    > .bebebe {
      padding-left: 8px;
    }
    > .bebebe::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      bottom: 6px;
      width: 1px;
      background: #dee3e6;
    }
  }
}
::v-deep .WindowContent {
  border: none !important;
  box-shadow: 0px 0px 20px 1px rgba(120, 120, 120, 0.2);
  border-radius: 8px 8px 8px 8px;
}
</style>