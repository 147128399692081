<template>
  <div class="sc-detail" v-loading="mapLoading">
    <div class="sc-detail-operation">
      <div class="sc-detail-operation-back" @click="handleGoPath('back')">
        <img
            src="../../assets/images/realTimePositioning/map-back.png"
            class="tab-icon"
            alt=""
            srcset=""
          />返回</div>
      <div class="sc-detail-operation-crumbs">
        <div class="sc-detail-operation-crumbs-button" @click="handleGoPath('/myHome')">首页</div>
        <div class="sc-detail-operation-crumbs-text" style="margin: 0 2px;"> / </div>
        <div class="sc-detail-operation-crumbs-button" @click="handleGoPath('back')">实时定位</div>
        <div class="sc-detail-operation-crumbs-text" style="margin: 0 2px;"> / </div>
        <div class="sc-detail-operation-crumbs-text">机械详情</div>
      </div>
    </div>
    <div class="sc-detail-kernel">
      <div class="sc-detail-kernel-top">
        <div class="sc-detail-kernel-top-left">
          <Iconfont :size="59" :name="vehicle.imageUrl"></Iconfont>
          <div class="sc-detail-kernel-top-left-content">
            <div class="sc-detail-kernel-top-left-content-tab">
              <div style="display: flex;gap: 8px;align-items: center;">
                <div class="sc-detail-kernel-top-left-content-label">{{ vehicle.P }}</div>
                <div v-for="item,index in (vehicle.terminalInfos || [])" :key="index" :class="['Header-state', 'Header-state' + (item.isOnline ? '1' : '')]">{{ terminalName[item.deviceType] }}-{{ item.isOnline ? '在线' : '离线' }}</div>
              </div>
              <!-- <VehicleStateLabel :state="vehicle.Z"></VehicleStateLabel> -->
              <!-- <div v-else :class="['Header-state', 'Header-state' + ([2,4].includes(vehicle.Z) ? '1' : '')]">AEB-{{ [2,4].includes(vehicle.Z) ? '在线' : '离线' }}</div> -->
            </div>
            <div class="sc-detail-kernel-top-left-content-text">{{ vehicle.machineryProductTypeStr }} {{ vehicle.frameNo }}</div>
          </div>
        </div>
        <div class="sc-detail-kernel-top-right">
          <div class="yl-button" :class="{ 'yl-button-disabled': vehicleIndex == 0 }" @click="handleNext(-1)">上一个</div>
          <div class="yl-button" :class="{ 'yl-button-disabled': vehicleIndex == vehicleIdList.length - 1 }" style="margin-left: 12px;" @click="handleNext(1)">下一个</div>
        </div>
      </div>
      <div class="sc-detail-kernel-tab">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="机械总览" name="RtpMapDetails"></el-tab-pane>
          <el-tab-pane label="工时统计" name="manHour"></el-tab-pane>
          <el-tab-pane label="报警信息" name="alarmDetails"></el-tab-pane>
          <el-tab-pane label="轨迹记录" name="trackDetails"></el-tab-pane>
          <el-tab-pane label="详细信息" name="infoDetails"></el-tab-pane>
          <el-tab-pane label="保单记录" name="policyDetails"></el-tab-pane>
          <el-tab-pane label="出险案件" name="claimDetails"></el-tab-pane>
          <el-tab-pane label="跟车记录" name="FollowCarRecord"></el-tab-pane>
          <el-tab-pane label="风控数据" name="riskControl"></el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="sc-detail-map">
      <components :is="activeName" ref="detailsTab" v-model="vehicle" :vehicleList="vehicleListOnMap" @change="init"></components>
    </div>
  </div>
</template>

<script>
import RtpMapDetails from "./components/RtpMapDetails";
import manHour from "./components/manHour";
import alarmDetails from "./components/alarmDetails";
import infoDetails from "./components/infoDetails";
import trackDetails from "./components/trackDetails";
import policyDetails from "./components/policyDetails";
import claimDetails from "./components/claimDetails";
import FollowCarRecord from "./components/FollowCarRecord";
import riskControl from "./components/riskControl";
import { getDeviceInfo } from "@/api/live-monitor-api";
import vehiclePicture from "@/utils/vehiclePicture";
export default {
  components: { RtpMapDetails, manHour, alarmDetails, infoDetails, trackDetails, policyDetails, claimDetails, FollowCarRecord, riskControl },
  data() {
    return {
      vehicle: {},
      vehicleListOnMap: [],
      iconUrl: '',
      activeName: 'RtpMapDetails',
      vehicleIdList: [],
      vehicleIndex: 0,
      mapLoading: false,
      terminalName: [
        '',
        'OVP',
        'OBD',
        '有线GPS',
        '无线GPS',
        'PFT',
        'BSD',
        'AEB'
      ],
				carImgList: [
					'aerial_work_machinery1',
					'aerial_work_machinery2',
					'compaction_machinery',
					'excavating_machinery',
					'excavating_machinery1',
					'hoisting_machinery',
					'hoisting_machinery3',
					'mining_machinery',
					'piling_machinery1',
					'shovel_transport_machinery0',
					'shovel_transport_machinery1',
					'shovel_transport_machinery3',
					'industrial_vehicles1',
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
				]
    }
  },
  created() {
    console.log(sessionStorage.getItem('vehicleIdList'));
    if (sessionStorage.getItem('vehicleIdList')) {
      this.$route.query.tabType ? this.activeName = this.$route.query.tabType : '';
      this.vehicleIdList = JSON.parse(sessionStorage.getItem('vehicleIdList'));
      this.vehicleIndex = this.$route.query.vehicleIndex !== undefined ? Number(this.$route.query.vehicleIndex) : 0;
      this.init();
    }
  },
  computed: {
    // 车辆类型图标
    vehicleIconName() {
      return this.iconUrl;
      // return getProductTypeIconName(vehicle.machineryProductType, vehicle.machineryEquipmentType);
    },
  },
  watch: {
    vehicle: {
      handler: async function (val) {
        if (!val || !val.machineryProductType) {
          return false;
        }
        // let url = await vehiclePicture(
        //   val.machineryProductType,
        //   val.machineryEquipmentType,
        //   val.Z
        // );
        // this.iconUrl = url;
      },
      deep: true,
    },
  },
  methods: {
    async init() {
      try {
        this.mapLoading = true;
        let res = await getDeviceInfo({
          vehicleId: this.vehicleIdList[this.vehicleIndex]
        });
        this.mapLoading = false;
        if (res.flag == 1) {
          this.vehicleListOnMap = res.obj.data;
          this.$nextTick(()=>{
            this.vehicle = res.obj.data[0] || {};
            this.$nextTick(()=>{
              this.$refs.detailsTab.getList ? this.$refs.detailsTab.getList('reset') : '';
            })
            this.$forceUpdate();
          })
        }else {
          this.vehicle = {};
          this.vehicleListOnMap = []
        }
      } catch (error) {
        
      }
    },
    handleTabClick(e) {
      console.log(e);
    },
    handleNext(e) {
      if ((e > 0 && (this.vehicleIdList.length - 1 > this.vehicleIndex)) || (e < 0 && this.vehicleIndex != 0)) {
        this.vehicleIndex += e;
        this.init();
      };
    },
    handleGoPath(type) {
      if (type == 'back') {
        this.$router.go(-1);
      }else {
        this.$router.push({
          path: type
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sc-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  &-kernel {
    background: #FFFFFF;
    border-radius: 10px;
    &-top {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 16px 0 24px;
      &-left {
        flex: 1;
        display: flex;
        align-items: center;
      ::v-deep img {
        border-radius: 4px !important;
        margin: 0 !important;
      }
        &-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 16px;
          height: 60px;
          &-tab {
            display: flex;
            align-items: center;
            .Header {
              &-state {
                height: 24px;
                line-height: 24px;
                font-size: 12px;
                color: #999999;
                padding: 0 8px;
                background: #EEEEEE;
                border-radius: 2px;
                border: 1px solid #E1E1E1;
              }
              &-state1 {
                color: #00BC0D;
                background: #E5F8E6;
                border: 1px solid #B7EDBA;
              }
              &-state2 {
                color: #FCA118;
                background: #FFF5E7;
                border: 1px solid #FFE4BD;
              }
            }
          }
          &-label {
            font-weight: bold;
            font-size: 16px;
            color: #333333;
          }
          &-text {
            font-size: 16px;
            color: #333333;
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;
      }
    }
    &-tab {
      ::v-deep .el-tabs {
        .el-tabs__item {
          font-size: 14px;
          color: #333333;
          padding: 0 20px;
        }
        .el-tabs__header {
          margin: 0;
        }
        .is-active {
          font-weight: bold;
          font-size: 14px;
          color: #0080FF;
        }
        .el-tabs__nav-wrap::after {
          height: 0;
        }
        .el-tabs__active-bar {
          height: 3px;
          background: #0080FF;
        }
      }
    }
  }
  &-map {
    flex: 1;
    border-radius: 10px;
    margin-top: 10px;
    overflow: hidden;
  }
  &-operation {
    display: flex;
    margin-bottom: 8px;
    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 72px;
      height: 33px;
      font-size: 14px;
      color: #FFFFFF;
      margin-right: 16px;
      background: #4278C9;
      border-radius: 17px 17px 17px 17px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
    &-crumbs {
      display: flex;
      align-items: center;
      &-button {
        cursor: pointer;
        font-size: 14px;
        color: rgba(0, 128, 255, 1);
      }
      &-text {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}
.yl-button {
  width: 104px;
  height: 44px;
  cursor: pointer;
  line-height: 44px;
  text-align: center;
  font-size: 14px;
  color: #FFFFFF;
  background: #0080FF;
  border-radius: 6px 6px 6px 6px;
}
.yl-button:hover {
  background: #61B0FF;
}
.yl-button-disabled {
  cursor: no-drop;
  color: #CCCCCC;
  background: #F5F5F5;
}
</style>